.rowMargin {
  margin-bottom: 20px;
}

.loading {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  opacity: 0.4;
  background: black;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.cursor {
  cursor: pointer;
}

.link {
  color: deepskyblue;
}

.fr {
  float: right;
}

.mr-l-10 {
  margin-left: 10px !important;
}

.borderBlock {
  border: 1px solid black;
}

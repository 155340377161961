section#homepage--top {
  background-image: url("../../images/newDesign/homepage.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 600px;
  font-family: "Montserrat", sans-serif;
  position: relative;
  padding: 100px 0;
  transition: 0.5s ease;
  @media (max-width: 767.98px) {
    padding: 30px 0;
    height: auto;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 0;
  }
  .above-backdrop {
    position: relative;
    z-index: 1;
  }
  .content-section {
    margin-top: 30px;
    color: #fff;
    h3 {
      font-size: 65px;
      font-weight: 700;
      margin-bottom: 20px;
      @media (max-width: 991.98px) {
        font-size: 40px;
      }
      @media (max-width: 767.98px) {
        font-size: 2rem;
      }
    }
    p {
      font-size: 20px;
    }
  }

  .form-section {
    background-color: rgba(255, 255, 255, 0.95);
    padding: 30px;
    padding-top: 50px;
    border-radius: 20px;
    box-shadow: 9px 12px 18px 3px rgba(0, 0, 0, 0.16);
    position: relative;

    @media (max-width: 767.98px) {
      padding: 30px 15px;
      margin-top: 80px;
    }
    .tour-package-scroll {
      padding-top: 20px;
      max-height: 290px;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .pre-purchased-scroll {
      // padding-top: 20px;
      max-height: 180px;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .itinery-item {
      border: 1px solid #333;
      margin-bottom: 15px;
      text-align: center;
      border-radius: 8px;
      padding: 2px;
      i {
        display: inline-block;
        margin-right: 5px;
        margin-left: 5px;
      }
    }
    .locationSelectContainer {
      &.minWidth250 {
        min-width: 250px;
      }
      .with-icon {
        padding-left: 27px;
      }
      .locationSelectMenu {
        z-index: 9999;
      }
    }
    .form-group {
      margin-bottom: 15px;
      margin-top: 8px;
    }
    .location-wrapper-row {
      position: relative;
    }
    .destination-swap-btn {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: inline;
      width: auto;
      &:focus {
        border: none;
        box-shadow: none;
      }
    }
    .btn-multistop {
      position: absolute;
      right: 10px;
      display: inline;
      width: auto;
      font-size: 10px;
      // background: #fff;
      // color: #000;
      top: -16px;
      padding: 0.2rem 0.5rem;
      &:focus {
        border: none;
        box-shadow: none;
      }
    }
    .form-menu-top {
      position: absolute;
      display: flex;
      flex-direction: row;
      padding-left: 0;
      top: -60px;
      .dropdown-toggle::after {
        display: none;
      }
      .dropdown-menu {
        z-index: 9999;
      }
      @media (max-width: 991.98px) {
        left: 0;
      }
      .form-menu-item {
        width: 80px;
        height: 75px;
        z-index: 1200;
        @media (max-width: 991.98px) {
          width: 60px;
          height: 67px;
        }

        margin-left: 7px;
        background-color: #fff;
        border-radius: 12px;
        box-shadow: 2px 2px 5px 3px rgba(0, 0, 0, 0.16);
        transition: 0.3s ease;
        span {
          font-size: 10px;
          line-height: 1.1;
          text-align: center;
          color: #111;
          cursor: pointer;
          margin: 0 2px;
          @media (max-width: 991.98px) {
            padding: 4px 0;
          }
          i {
            margin-top: 5px;
            height: 35px;
            font-size: 25px;
            display: block;
            @media (max-width: 991.98px) {
              height: 30px;
              font-size: 20px;
            }
          }
        }
        &.active {
          background-color: $primaryColor;
          span {
            color: #fff;
          }
        }
        &:hover {
          transform: scale(1.02);
          box-shadow: 10px 10px 20px 7px rgba(0, 0, 0, 0.21);
        }
      }
    }
    h3 {
      font-size: 1.5rem;
      font-weight: 600;
      @media (max-width: 575.98px) {
        font-size: 1.2rem;
      }
    }
    label {
      font-size: 14px;
      font-weight: 700;
    }
  }
}

section#home--about-us {
  .content-section {
    padding-left: 100px;
    @media (max-width: 575.98px) {
      padding-left: 0;
    }
    .contact-details {
      i {
        color: $primaryColor;
      }
    }
  }
  .about-img {
    border-radius: 30px;
    overflow: hidden;
    box-shadow: 9px 12px 18px 3px rgba(0, 0, 0, 0.161);
    img {
      width: 100%;
    }
    @media (max-width: 575.98px) {
      margin-bottom: 40px;
      img {
        object-fit: cover;
        // height: 280px;
      }
    }
  }
}

section#home--subscribe {
  .colored-section {
    position: relative;
    z-index: 0;
    background: linear-gradient(
      to right bottom,
      $secondaryColor,
      $secondaryColorLightTransparent
    );
    border-radius: 40px;
    color: #fff;
    padding: 50px 0;
    margin: 0 150px;
    @media (max-width: 991.98px) {
      margin-left: 0;
      margin-right: 0;
    }
    @media (max-width: 575.98px) {
      margin-left: 0;
      margin-right: 0;
    }
    box-shadow: 9px 12px 18px 3px rgba(0, 0, 0, 0.161);

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-image: url("../../images/newDesign/dotted.png");
      background-size: contain;
      opacity: 0.15;
      background-position: center;
      // transform: scale(0.7);
      background-repeat: repeat;
      z-index: -1;
    }
  }
  .subscribe-form {
    position: relative;
    padding: 12px;
    border-radius: 40px;
    background-color: #fff;
    box-shadow: 9px 12px 18px 3px rgba(0, 0, 0, 0.161);

    input.form-control {
      padding-left: 20px;
      border-radius: 40px;
      border: 0;
      box-shadow: none;
    }
    .btn {
      position: absolute;
      right: 12px;
      padding: 8px 25px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.tour-package-item {
  text-align: center;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 20px;
  background-color: #fff;
  .image {
    position: relative;
    img {
      width: 100%;
    }
    .duration-tag {
      position: absolute;
      left: 10px;
      top: 10px;
      border: 1px solid #fff;
      background-color: rgba(#000, 0.6);
      border-radius: 40px;
      color: #fff;
      font-size: 12px;
      padding: 3px 15px;
    }
    .duration {
      position: absolute;
      right: 10;
      top: 10;
    }
    .inclusions {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 5px;
      color: #fff;
      font-size: 12px;
      .icon {
        width: 20px;
        height: 20px;
        padding: 2px;
        border-radius: 50%;
        background-color: #111;
        display: inline-block;
        position: relative;
        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  .description {
    text-align: center;
    .package-name {
      margin-top: 10px;
      position: relative;
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: 700;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:after {
        position: absolute;
        left: 50%;
        bottom: -5px;
        content: "";
        width: 40px;
        height: 3px;
        background-color: var(--bs-secondary);
        display: block;
        margin: 0 auto;
        transform: translateX(-50%);
      }
    }
    .duration,
    .cities {
      font-size: 14px;
      margin-top: 10px;
    }
    .cities {
      margin-bottom: 10px;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 15px;
      overflow: hidden;
    }
    .price {
      font-size: 24px;
      .subtitle {
        font-size: 12px;
      }
    }
  }
  .btn-container {
    padding: 15px 0;
    .view-btn {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

section#home--banners {
  .image {
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 9px 12px 18px 3px rgba(0, 0, 0, 0.161);
    transition: 0.3s ease;
    &:hover {
      transform: scale(1.1);
    }
  }
  .banner-item {
    img.img-fluid {
      width: 100%;
    }
    .title {
      margin-top: 20px;
      a {
        color: inherit;
        text-decoration: none;
        font-weight: 700;
      }
    }
  }
}

:root {
  --bs-primary: #ff6c2d;
  --bs-primary-rgb: rgb(0, 96, 121);
  --bs-secondary-color-light-transparent: rgba(0, 96, 121, 0.6);
  --bs-secondary: #006079;

  --grey: #4d4d4d;
  --light-grey: #dedede;
  --light-2-grey: #f6f6f6;
  --white: #ffffff;
}

$primaryColor: var(--bs-primary);
$secondaryColor: var(--bs-secondary);
$secondaryColorLightTransparent: var(--bs-secondary-color-light-transparent);

@import "nav";
@import "common";
@import "home";
@import "formInputs";
@import "passengerDetails";
@import "components/flightPassengers";
@import "components/fareRules";
@import "components/eticket";
@import "footer";
@import "settingTab/otherSettings";
@import "settingTab/permissionGroups";

footer.main {
  margin-top: 100px;
  padding-top: 20px;
  background: conic-gradient(from 315deg, #f7f7f7a4, #ffffff);
  border-top: 2px solid $primaryColor;
  .footer-logo {
    max-width: 150px;
  }
  @media (max-width: 767.98px) {
    padding-bottom: 20px;
  }
  .about-us {
    opacity: 0.8;
    font-size: 14px;
  }
  .main-heading {
    @media (max-width: 767.98px) {
      margin-top: 30px;
    }
  }

  .footer-links {
    margin-top: 30px;
    opacity: 0.8;
    font-size: 14px;
    padding-left: 15px;
    li {
      list-style-type: none;
      margin: 10px 0;
      position: relative;
      i {
        position: absolute;
        left: -18px;
        top: 3px;
        color: $secondaryColor;
      }
      a {
        color: #000;
        text-decoration: none;
        transition: 0.3s ease;
        &:hover {
          color: $primaryColor;
          font-weight: 700;
        }
      }
    }
  }

  .footer-logo-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dedede;
    padding-bottom: 15px;

    .social-icons {
      i {
        color: $primaryColor;
        border: 1px solid $primaryColor;
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: $primaryColor;
          color: #ffffff;
        }
      }
      ul {
        padding: 0;
        margin: 0;
        li {
          display: inline-block;
          padding-right: 20px;
          i {
            font-size: 18px;
          }
        }
      }
      @media (max-width: 576px) {
        i {
          height: 30px;
          width: 30px;
          font-size: 12px;
        }
        ul {
          padding: 0;
          margin: 0;
          li {
            display: inline-block;
            padding-right: 6px;
            i {
              font-size: 18px;
            }
          }
        }
      }
    }
  }

  .footer-content {
    padding-top: 30px;
    padding-bottom: 30px;
    // background-color: var(--primary);
    background: none;
    // background: conic-gradient(from 315deg, #ffffff, #d5e5ff);
  }

  .copyright {
    border-top: 1px solid $primaryColor;
    p {
      color: $primaryColor;
      font-size: 12px;
    }
  }
}

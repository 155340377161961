.passenger-box {
  border-radius: 0.5rem;
  position: relative;
  padding: 5px;
  box-shadow: 0rem 0rem 1rem 5px rgba(0, 0, 0, 0.15);
  .remove-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    width: auto;
    background: #ef1a1a;
    color: #fff;
    border: 0;
    border-radius: 6px;
  }
}

.fixdep-sectors-container {
  .fix-dep-sector-item {
    margin-bottom: 4px;
    margin-top: 4px;
    border: 1px solid #dfdfdf;
    padding: 7px 8px;
    border-radius: 7px;
    background-color: #fff;
    transition: 0.3s ease;
    font-size: 13px;
    cursor: pointer;
    i {
      color: var(--bs-primary);
    }
    &:hover {
      i {
        color: #fff !important;
      }
      background-color: var(--bs-primary);
      color: #fff;
      transform: scale(1.05);
    }
  }
}

.fdLabelblink {
  animation: blink-animation 1.5s steps(5, start) infinite;
  -webkit-animation: blink-animation 1.5s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    transform: scale(1);
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    transform: scale(1.2);
    visibility: hidden;
  }
}

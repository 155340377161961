// var(--bs-primarya)
.tiles-container {
  padding: 10px 0px;

  li {
    cursor: pointer !important;
  }
  .rc-menu-submenu-title {
    padding: 10px 25px !important;
    border: none;
    min-width: 120px;
    border-radius: 6px;
    margin: 0px 4px;
    background-color: rgba(255, 255, 255, 0.147);
    // transition: 0.3s ease-in-out;

    @media (max-width: 767.98px) {
      padding: 5px 15px !important;
      min-width: 0;
    }
    @media (max-width: 576.98px) {
      padding: 5px 10px !important;
      min-width: 0;
    }
    span {
      color: var(--white);
    }
  }

  .rc-menu-submenu-selected {
    background-color: var(--bs-primary);
  }

  // hover effect classes
  .rc-menu-horizontal {
    border-bottom: none;
    background: none;

    .rc-menu-submenu {
      border-bottom: none;
    }
    .rc-menu-submenu-active {
      border-bottom: none;
      background-color: white !important;
      border-radius: 6px !important;
      transition: 0.4s ease-in;

      .rc-menu-submenu-title {
        background: white;
        color: var(--bs-primary) !important;
        margin: 0px 4px;

        // border-radius: 10px !important;
        .topMenuBtn {
          color: var(--bs-primary) !important;
        }
        .topMenuIcon {
          color: var(--bs-primary) !important;
        }
        .rc-menu-item {
          cursor: pointer !important;
        }
      }
    }
  }

  // .rc-menu-submenu {
  //   .rc-menu-vertical {
  //     .rc-menu-item {
  //       background-color: var(--bs-primary) !important;
  //     }
  //     .rc-menu-item-active {
  //       background-color: var(--bs-primary) !important;
  //     }
  //   }
  // }

  .topMenuIcon {
    color: white !important;
    font-size: 20px !important;
    display: block;
    margin-right: 10px !important;
    margin: 0 auto;

    @media (max-width: 767.98px) {
      margin-right: 0px !important;
    }
  }
  .topMenuBtn {
    color: white !important;
    // capitalise
    text-transform: uppercase !important;
    font-size: 12px;
    display: flex;
    align-items: center;

    @media (max-width: 767.98px) {
      display: block;
      font-size: 10px;
    }
    @media (max-width: 566.98px) {
      font-size: 10px;
      .topMenuIcon {
        font-size: 14px !important;
      }
      text-transform: capitalize !important;
    }
  }
}
.rc-menu-item {
  cursor: pointer !important;
}
.rc-menu-submenu-title {
  cursor: pointer !important;
}

#sp-pancard-verify-page {
  .section {
    margin-bottom: 60px;
  }

  a {
    text-decoration: none;
    color: rgb(26, 144, 255);
    &:hover {
      color: grey;
    }
  }
  h1 {
    font-weight: 600;
    color: rgb(26, 144, 255);
    span {
      font-weight: 400;
      color: rgb(36, 36, 36);
    }
  }
  h6 {
    color: rgb(52, 52, 52);
    font-weight: 500;
  }
  .button {
    margin-top: 20px;
  }

  .item {
    min-height: 100%;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: 1px solid rgb(228, 228, 228);
    padding: 30px;
    border-radius: 20px;
    color: grey;
    font-weight: 600;
  }
  .main-item {
    min-height: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: 1px solid rgb(228, 228, 228);
    padding: 30px;
    border-radius: 20px;
    background-color: rgb(26, 144, 255);
    color: white;
    font-size: 20px;
    font-weight: 600;
  }
}

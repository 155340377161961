h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}

.bg-primary {
  background-color: $primaryColor !important;
}
.bg-secondary {
  background-color: $secondaryColor !important;
}
.color-white {
  color: #fff !important;
}

.text-primary {
  color: $primaryColor !important;
}

.bg-light-grey {
  background-color: rgba(#ededed, 0.369);
}

.form-group {
  @media (max-width: 575.98px) {
    // margin-bottom: 15px;
  }
  .form-control {
    border: 1px solid #ced4da !important;
    @media (max-width: 575.98px) {
      font-size: 0.8rem !important;
    }
  }
  &.with-icon {
    position: relative;
    label {
      position: absolute;
      top: -17px;
      font-size: 12px !important;
      font-weight: 300 !important;
    }
    .form-icon {
      position: absolute;
      left: 8px;
      top: 7px;
      font-size: 13px;
      z-index: 2;
    }
    .form-control {
      padding-left: 30px;
    }
  }
  &.less-padding {
    .form-control {
      padding-left: 25px;
    }
  }
}

.btn-lg {
  padding-left: 30px;
  padding-right: 30px;
}

.section-heading {
  font-size: 2rem;
  @media (max-width: 575.98px) {
    font-size: 1.5rem;
  }
  margin-bottom: 2rem;
}
.heading-content {
  opacity: 0.85;
  font-size: 0.9rem;
  margin-bottom: 2rem;
  line-height: 1.5;
}

.after-heading,
.section-heading {
  &:after {
    content: "";
    display: block;
    width: 80px;
    height: 4px;
    background-color: $primaryColor;
    margin-top: 10px;
  }
  &.white {
    color: #fff;
  }
  &.center {
    text-align: center;
    &:after {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.btn-primary {
  background-color: $primaryColor;
  border-color: $primaryColor;
  color: #fff;
  &:hover {
    background-color: #fff;
    border-color: $primaryColor;
    color: $primaryColor;
  }
  &:focus {
    background-color: $primaryColor;
    border-color: $primaryColor;
    color: #fff;
    box-shadow: none;
  }
}
.btn-outline-primary {
  background-color: transparent;
  border-color: $primaryColor;
  color: $primaryColor;
  &:hover {
    background-color: $primaryColor;
    border-color: $primaryColor;
    color: #fff;
  }

  &:focus {
    background-color: $primaryColor;
    border-color: $primaryColor;
    color: #fff;
    box-shadow: none;
  }
}
.btn {
  border-radius: 40px;
}

.section-padding {
  padding: 5rem 0;
}

.bootstrap-datepicker {
  .MuiInputBase-input {
    // border-bottom: 0;
    // border: 1px solid #ced4da;
    // border-left: 0;
    border: 0;
  }
  .MuiInput-underline:before {
    border-bottom: 0;
  }
}

/* Hide up down arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.customFilledDatepicker {
  .MuiOutlinedInput-input {
    padding-top: 10.5px;
    padding-bottom: 10.5px;
  }
  .MuiOutlinedInput-notchedOutline {
    background-color: #fff;
  }
}

.low-visibility {
  opacity: 0.4;
}

.blink {
  font-size: 10px;
  color: red;
  margin-right: 0.5rem;
  animation: blink 0.1s steps(5, start) infinite;
  -webkit-animation: blink 1s steps(5, start) infinite;
}

.filter-float-button {
  @media (min-width: 991.98px) {
    display: none;
  }
  position: fixed;
  bottom: 30px;
  left: 30px;
  // @media (max-width: 991.98px) {
  //   bottom: 200px;
  // }
  // @media (max-width: 767.98px) {
  //   bottom: 100px;
  // }
  .btn-warning {
    background-color: var(--primary);
    border: none;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}
@keyframes blink {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink {
  to {
    visibility: hidden;
  }
}

.navbar-brand {
  max-width: 150px;
  @media (max-width: 400px) {
    max-width: 140px;
  }
  @media (max-width: 380px) {
    max-width: 120px;
  }
}
.navbar-nav {
  // @media (min-width: 992px) {
  align-items: center;
  // }
  flex-direction: row !important;
  @media (max-width: 991.98px) {
    padding-right: 30px;
  }
  .navbar-toggler-menu-item-fixed {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
  }
  .nav-item {
    border: 1px solid $primaryColor;
    border-radius: 0px;
    padding: 0 1rem;
    margin: 0 5px;
    height: 100%;
    @media (max-width: 767.98px) {
      padding: 0 0.6rem;
    }
    &:hover {
      background-color: $primaryColor;

      .nav-link {
        color: #fff;
      }
    }
    .nav-link {
      color: $primaryColor;
      padding-top: 3px;
      padding-bottom: 3px;
      &.active {
        &:after {
          content: "";
          display: block;
          width: 30px;
          height: 4px;
          background-color: $primaryColor;
          margin-top: 4px;
        }
      }
    }
  }
}

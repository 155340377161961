.user-actions-menu {
  padding: 0;
  width: 100px;
  margin: 0 auto;
  margin-top: 8px;
}
.user-actions-menu .rc-menu-submenu-title {
  padding: 5px !important;
}
.rc-menu-item {
  padding: 5px 10px !important;
}

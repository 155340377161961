.eticket-wrapper {
  .logo {
    max-width: 140px;
  }
  .fd-ticketQueue-sub-heading {
    background-color: #dadada;
  }
}

.enquiryQueueSubmenu {
  background-color: rgba(255, 255, 255, 0) !important;
  border-bottom: none !important;
  border-radius: 0px 50px 50px 0px !important;

  .rc-menu-submenu-title {
    padding: 10px 20px !important;
    margin: 0px 0px !important;
  }

  .rc-menu-submenu-active {
    border-bottom: none !important;
    background-color: var(--bs-primary) !important;
    border-radius: 0px 50px 50px 0px !important;
    // transition: 0.4s ease-in;

    .rc-menu-submenu-title {
      // border-bottom: none !important;
      padding: 11px 20px !important;
      background: var(--bs-primary);
      color: white !important;
      margin: 0px 0px !important;
    }
  }
}

#successEnquiryPage {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    font-size: 60px;
    margin-bottom: 20px;
  }
}

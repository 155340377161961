#permission-group-container {
  min-height: calc(100vh - 380px);
  .btn:disabled {
    background-color: var(--bs-primary) !important;
    border-color: var(--bs-primary) !important;
    opacity: 0.7;
  }
  .btn-primary {
    &:active {
      background-color: var(--bs-primary) !important;
      border-color: var(--bs-primary) !important;
    }
  }
}

.top-section {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  .right {
    display: flex;
    align-items: start;
    justify-content: center;
    min-width: 20%;
  }
}

.manage-permission-modal {
  @media (max-width: 991.9px) {
    display: none;
  }
}

.permission-list-container {
  max-height: 400px;
  min-height: 400px;
  overflow-x: hidden;
  transition: max-height 0.8s ease;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--bs-primary);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:not(:active) {
    background: var(--bs-primary);
  }
  .heading {
    font-weight: bold;
    justify-content: start;
  }

  .permission-option {
    padding: 8px 0px;
    padding-right: 8px;
    margin: 10px 5px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.21);
    cursor: pointer;
    transition: transform 0.2s ease;

    &.selected-option {
      color: var(--bs-primary);
      font-weight: 600;
      box-shadow: 0px 0px 5px 0px var(--bs-primary);
      border-left: 4px solid var(--bs-primary);
    }
    input {
      cursor: pointer;
      &:checked {
        background-color: var(--bs-primary);
        border: 1px solid var(--bs-primary);
      }
    }
    &:hover {
      transform: translateX(2px);
      border-left: 4px solid var(--bs-primary);
      box-shadow: 0px 0px 6px 0px var(--bs-primary);
    }
    &.permission-checkbox-container {
      position: relative;
      transition: transform 0.2s ease;
      &:hover {
        color: #198754;
        transform: translateY(-2px);
        border-left: none;
        box-shadow: 0px 0px 6px 0px #198754;
      }
      &:active {
        font-weight: 600;
      }
    }
  }

  .animated-checkbox-container {
    .animated-checkbox-btn {
      background-color: var(--white);
      border: 1px solid var(--light-grey);
      border-radius: 30px;
      padding: 2px 10px;
      display: flex;
      align-items: center;
      cursor: pointer;
      input[type="checkbox"] {
        cursor: pointer;
      }
      input[type="checkbox"]:checked {
        display: none;
      }
      label {
        font-size: var(--sm-font);
        color: var(--grey);
        &:hover {
          color: #198754 !important;
        }
      }

      .custom-checkbox {
        height: 13px;
        width: 13px;
        border: 0.5em solid rgba(109, 189, 98, 0.712);
        animation: shrink-bounce 200ms cubic-bezier(0.4, 0, 0.23, 1);
        border-radius: 3px;
        position: relative;
        &::before {
          content: "";
          font-size: larger;
          position: absolute;
          bottom: -0.5em;
          right: -0.01em;
          border-right: 3px solid transparent;
          border-bottom: 3px solid transparent;
          transform: rotate(45deg);
          transform-origin: 0% 100%;
          animation: checkbox-check 125ms 250ms cubic-bezier(0.4, 0, 0.23, 1)
            forwards;
        }
        @keyframes shrink-bounce {
          0% {
            transform: scale(1);
          }
          33% {
            transform: scale(0.55);
          }
          100% {
            transform: scale(1);
          }
        }
        @keyframes checkbox-check {
          0% {
            width: 0;
            height: 0;
            border-color: #212121;
            transform: translate3d(0, 0, 0) rotate(45deg);
          }
          33% {
            width: 0.3em;
            height: 0;
            transform: translate3d(0, 0, 0) rotate(45deg);
          }
          100% {
            width: 0.3em;
            height: 0.8em;
            border-color: #212121;
            transform: translate3d(0, -0.5em, 0) rotate(45deg);
          }
        }
      }
    }
  }
}
.mobile-hide {
  @media (max-width: 991.9px) {
    display: none !important;
  }
}
.mobile-show {
  @media (min-width: 991.9px) {
    display: none !important;
  }
}

.permission-tabs {
  display: none;
  .nav {
    border-color: var(--bs-primary) !important;
    .nav-item {
      .nav-link {
        // margin: 0;
        color: var(--bs-primary) !important;
        &.active {
          background-color: var(--bs-primary) !important;
          color: white !important;
        }
      }
    }
  }
  .tab-content {
    display: none;

    @media (max-width: 991.9px) {
      display: block;
    }
  }
  @media (max-width: 991.9px) {
    display: block;
  }
}

#user-permission-group-list-container {
  .group-name {
    font-size: large;
    font-weight: 500;
    word-break: break-word;
    transition: transform 0.1s ease;
    &:hover {
      cursor: pointer;
      color: var(--bs-primary);
      transform: translateY(-1px);
    }
    i {
      font-size: small;
      position: relative;
      top: -8px;
    }
  }
  .badge {
    background-color: rgb(118, 118, 118);
    font-size: x-small;
    font-weight: 500;
    padding: 3px 6px;
    color: white;
  }
}
